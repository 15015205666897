import { Link } from "gatsby"
import React from "react"
import Layout from "../components/Layout"

const NotFound = () => {
  return (
    <Layout>
      <section className="section full-section">
        <div className="container">
          <div className="hero-body">
            <div>
              <div className="title my-6">404 - We couldn't find that page.</div>
              <div className="subtitle my-6">
                Maybe you can find what you need here?
              </div>
              <Link className="button is-large is-gradient" to="/">
                Home{" "}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFound
